import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { RESEND_CHECKR_APPLICATION_URL } from "../constants";
import {
  type CheckrApplicationStatusResponse,
  checkrApplicationStatusResponseSchema,
} from "../types";

export function useResendCheckrInvitation(
  options: UseMutationOptions<CheckrApplicationStatusResponse, AxiosError> = {}
): UseMutationResult<CheckrApplicationStatusResponse, AxiosError, void> {
  return useMutation({
    mutationKey: [RESEND_CHECKR_APPLICATION_URL],
    mutationFn: async () => {
      const response = await get({
        url: RESEND_CHECKR_APPLICATION_URL,
        responseSchema: checkrApplicationStatusResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.RESEND_CHECKR_INVITATION_FAILURE,
    },
    ...options,
  });
}
