import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import { deprecatedDoNotUseGraphQlCH25924 } from "./deprecatedDoNotUseGraphQlCH25924";
import {
  type GetHcpMissingRequirementsForDateQueryParams,
  type GetHcpMissingRequirementsForDateResponse,
} from "./types";

export function useGetMissingRequirementsForDate(
  options: UseMutationOptions<
    GetHcpMissingRequirementsForDateResponse,
    unknown,
    GetHcpMissingRequirementsForDateQueryParams
  > = {}
) {
  return useMutation({
    mutationFn: deprecatedDoNotUseGraphQlCH25924,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_MISSING_REQUIREMENTS_BY_DATE_FAILURE,
    },
    ...options,
  });
}
