/**
 * This function do not check if the selected qualification is allowed for the shift.
 * It only decides which qualification to send between user selected or shift qualification for document check API
 *
 * Why this needed ?
 * NURSE is not a real qualification, it's a catch all qualification for both RN and LVN.
 *
 * So when we send the shift qualification for a NURSE shift, the document check will fail.
 * That's why we need to send the selected worker qualification in that case.
 * */
export function getQualificationForDocumentCheck(params: {
  shiftQualificationRequirement: string;
  selectedWorkerQualification: string;
}) {
  const { shiftQualificationRequirement, selectedWorkerQualification } = params;

  // If the shift is a nurse shift, we need to send the selected worker qualification as NURSE shift can be booked by
  // both RN and LVN and there is no actual nurse qualification.
  if (shiftQualificationRequirement.toLowerCase() === "nurse") {
    return selectedWorkerQualification;
  }

  return shiftQualificationRequirement;
}
