import { z } from "zod";

import { documentStatusSchema, hcpDocumentSchema } from "../types";

export const getFilteredDocumentsApiResponseSchema = z.object({
  data: z.object({
    documentList: z.array(hcpDocumentSchema),
  }),
});

export type GetFilteredDocumentsApiResponse = z.infer<typeof getFilteredDocumentsApiResponseSchema>;

export const getFilteredDocumentQueryParamsSchema = z.object({
  hcpId: z.string(),
  limit: z.number(),
  sort: z.object({
    createdAt: z.number(),
  }),
  filter: z.object({
    status: documentStatusSchema,
    fulfilledRequirementId: z.string(),
    workerUploaded: z.boolean(),
  }),
});

export type GetFilteredDocumentQueryParams = z.infer<typeof getFilteredDocumentQueryParamsSchema>;

export const getHcpMissingRequirementsForDateQueryParamsSchema = z.object({
  hcfId: z.string(),
  date: z.string(),
  qualification: z.string(),
  notifySupport: z.boolean().optional(),
});

export type GetHcpMissingRequirementsForDateQueryParams = z.infer<
  typeof getHcpMissingRequirementsForDateQueryParamsSchema
>;

export const hcpMissingRequirementsForDateSchema = z.object({
  _id: z.string(),
  reqId: z.string(),
  name: z.string(),
  level: z.string(),
  requiredBy: z.array(z.string()),
  hcfType: z.array(z.string()),
  instructions: z.string(),
  description: z.string(),
  visibleToHCP: z.boolean(),
  expiry: z.string().nullish(),
});

export type HcpMissingRequirementsForDate = z.infer<typeof hcpMissingRequirementsForDateSchema>;

export const getHcpMissingRequirementsForDateResponseSchema = z.object({
  data: z.object({
    hcpMissingRequirementsForDate: z.array(hcpMissingRequirementsForDateSchema),
  }),
});

export type GetHcpMissingRequirementsForDateResponse = z.infer<
  typeof getHcpMissingRequirementsForDateResponseSchema
>;
