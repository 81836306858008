import { get } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { CHECKR_APPLICATION_STATUS_URL } from "../constants";
import {
  type CheckrApplicationStatusResponse,
  checkrApplicationStatusResponseSchema,
} from "../types";

export function useGetCheckrApplicationStatus(
  options: UseQueryOptions<CheckrApplicationStatusResponse> = {}
): UseQueryResult<CheckrApplicationStatusResponse> {
  return useQuery({
    queryKey: [CHECKR_APPLICATION_STATUS_URL],
    queryFn: async () => {
      const response = await get({
        url: CHECKR_APPLICATION_STATUS_URL,
        responseSchema: checkrApplicationStatusResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_CHECKR_APPLICATION_STATUS_FAILURE,
    },
    ...options,
  });
}
