import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import {
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_FILTERED_DOCUMENTS_QUERY_KEY,
  deprecatedGetFilteredDocumentsUrlDoNotUseGraphQlCH23232,
} from "./deprecatedDoNotUseGraphQlCH23232";
import { type GetFilteredDocumentQueryParams, type GetFilteredDocumentsApiResponse } from "./types";

export function useGetFilteredDocuments(
  params: GetFilteredDocumentQueryParams,
  options: UseQueryOptions<GetFilteredDocumentsApiResponse> = {}
): UseQueryResult<GetFilteredDocumentsApiResponse> {
  return useQuery({
    queryKey: [DEPRECATED_DO_NOT_USE_GRAPHQL_GET_FILTERED_DOCUMENTS_QUERY_KEY, params],
    queryFn: deprecatedGetFilteredDocumentsUrlDoNotUseGraphQlCH23232(params),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FILTERED_DOCUMENTS_FAILURE,
    },
    ...options,
  });
}
