import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  type OnboardingCheckrInvitationRequest,
  onboardingCheckrInvitationRequestSchema,
  type OnboardingCheckrInvitationResponse,
  onboardingCheckrInvitationResponseSchema,
} from "../types";

export const SEND_ONBOARDING_CHECKR_INVITATION_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/onboarding/checkr/invitation`;

export function useSendOnboardingCheckrInvitation(
  options: UseMutationOptions<
    OnboardingCheckrInvitationResponse,
    AxiosError<string>,
    OnboardingCheckrInvitationRequest
  > = {}
): UseMutationResult<
  OnboardingCheckrInvitationResponse,
  AxiosError<string>,
  OnboardingCheckrInvitationRequest
> {
  return useMutation({
    mutationKey: [SEND_ONBOARDING_CHECKR_INVITATION_URL],
    mutationFn: async (invitationRequest: OnboardingCheckrInvitationRequest) => {
      const response = await post({
        url: SEND_ONBOARDING_CHECKR_INVITATION_URL,
        responseSchema: onboardingCheckrInvitationResponseSchema,
        requestSchema: onboardingCheckrInvitationRequestSchema,
        data: invitationRequest,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SEND_ONBOARDING_CHECKR_INVITATION_FAILURE,
    },
    ...options,
  });
}
