import { isDefined } from "@clipboard-health/util-ts";
import {
  defaultHcpRequirementStatusApiResponse,
  deprecatedGetRequirementStatusDoNotUseGraphQlCH23034,
  GET_REQUIREMENTS_STATUS_PATH,
} from "@src/appV2/Accounts/Documents/api/deprecatedDoNotUseGraphQlCH23034";
import { type Facility } from "@src/appV2/Facilities/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type HcpRequirement, type HcpRequirementStatusResponse, MappingLevel } from "../types";

interface UseGetMissingRequirements {
  pendingDocuments: Set<string>;
  missingDocuments: Set<HcpRequirement>;
}

export function useGetMissingRequirements(
  params: { workerId: string; facility: Pick<Facility, "fullAddress" | "type" | "userId"> },
  options: UseQueryOptions<HcpRequirementStatusResponse> = {}
): UseQueryResult<HcpRequirementStatusResponse> & UseGetMissingRequirements {
  const { workerId, facility } = params;
  const response = useQuery({
    queryKey: [`${GET_REQUIREMENTS_STATUS_PATH}/missing`, workerId],
    queryFn: deprecatedGetRequirementStatusDoNotUseGraphQlCH23034(workerId),
    meta: { logErrorMessage: APP_V2_APP_EVENTS.GET_REQUIREMENT_STATUS_FAILURE },
    staleTime: 5000,
    ...options,
  });

  const hcpRequirementStatus =
    response.data?.data.hcpRequirementStatus ?? defaultHcpRequirementStatusApiResponse;

  const pendingDocuments = new Set<string>();
  const missingDocuments = new Set<HcpRequirement>();

  hcpRequirementStatus.requirements.forEach((requirement) => {
    if (
      hcpRequirementStatus.completed?.includes(requirement.reqId) ||
      pendingDocuments.has(requirement.name)
    ) {
      return;
    }

    if (
      // eslint-disable-next-line unicorn/prefer-spread
      Array.from(missingDocuments).some(
        (missingDocument) => missingDocument.reqId === requirement.reqId
      )
    ) {
      return;
    }

    let isDocumentRequiredforFacilityType = false;
    if (isDefined(facility.type) && requirement.hcfType.includes(facility.type)) {
      isDocumentRequiredforFacilityType =
        requirement.requiredBy.includes(facility.fullAddress?.metropolitanStatisticalArea ?? "") ||
        requirement.requiredBy.includes(facility.fullAddress?.state ?? "") ||
        requirement.requiredBy.includes(facility.fullAddress?.region ?? "") ||
        requirement.level === MappingLevel.GLOBAL;
    }

    const isDocumentRequired =
      isDocumentRequiredforFacilityType || requirement.requiredBy.includes(facility.userId ?? "");

    if (isDocumentRequired) {
      if (hcpRequirementStatus.pending?.includes(requirement.reqId)) {
        pendingDocuments.add(requirement.name);
      } else {
        missingDocuments.add(requirement);
      }
    }
  });

  return {
    ...response,
    pendingDocuments,
    missingDocuments,
  };
}
